.footer{
  padding:40px 0 20px;
  background: url(../img/green.png) no-repeat right top;
  
  &-table{
    width: 100%;
    font-size: $font-size-small;
    color:#333;
    td{
      vertical-align: top;
      
      &:nth-child(4){
        width:450px;
      }
    }
    div{
      padding: 0 15px;
      border-left:2px solid #7f7d7e;
      p{
        margin: 0;
      }
    }
    img{
      margin:0 15px 10px;
    }
    
  }
}