//viewports
$vs : 320px;
$vm : 768px;
$vl : 960px;
$vh : 1200px;



$font-size-base: 14px;
$font-size-small: 13px;

$color-base: #333;
$color-main: #006d73;
$color-second: #009ba3;

$font-family: Arial, sans-serif;