.header{
  padding:10px 0 0;
  
  &-logo{
    float: left;
    margin-top:30px;
    padding: 10px 0 10px;
  }
  
  &-login{
    float:right;
    font-size: $font-size-small;
    width:187px;
    text-align: center;
    
    h3{
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      margin-top:0;
      margin-bottom:10px;
    }
    .form-group{
      margin-bottom:5px;
      input{
        border-left:none;
        width:147px;
      }
      label{
        height: 20px;
      }

    } 
    .btn{
      margin:5px 0;
    } 

  }
  &-login_form{
    margin-bottom:5px;
  }
  
  &-menu{
    clear: both;
    padding: 25px 0 0;
    text-align: right;
    
    ul{
      margin:0;
    }
    &:after{
      content: "";
      display:block;
      height:13px;
      margin:7px 0 2px;
      background-image: linear-gradient(to right, #f58320,#573300)
    }
    
    li{
      display: inline-block;
      margin-right: 25px;
      &:hover a,
      &.active a{
        background: #f58220;
        color:#fff;
      }
    }
    li+li{
      margin-left:50px;
    }
    a{
      display: block;
      padding: 3px 25px;
      color: #777;
      font-size: 18px;
      text-decoration: none;
    }
  }
}