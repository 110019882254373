html{
  height: 100%;
}
body{
  min-height: 100vh;
  color: #777;
  font-family: $font-family;
  font-size: $font-size-base;
  line-height: 1.5;
}

a{
  color:#0087FF;
  &:hover{
    color:#0362B7;
  }
}
.wrapper{
  min-height: 100vh;
  background: linear-gradient(to bottom right, #fff, #f0f0f0);
  
}
.container{
  width: 1200px;
  margin:0 auto;
}

.btn{
  display:block;
  width:100%;
  padding:5px 0;
  margin:15px 0 5px;
  border: none;
  background: #818285;
  color:#fff;
  font-size: 11px;
  outline:none;
  text-decoration: none;
      
  &:hover{
    background: #aaa;
    color:#fff;
  }
  &:active{
    box-shadow: inset 0 1px 3px #333;
  }
}

.alert{
  position: relative;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  color: #fff;
  &-success{
    background: green;
  }
}