.content{
  form{
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #ECECEC;
    
    .form-group{
    }
    label{
      flex: 1;
      div{
        font-weight: bold;
        color: #F48320;
        border-bottom: 0;
      }
    }
  }
}

.form-group{
  display: flex;
  margin-bottom: 10px;
  

  
  input{
    border:2px solid #ccc;
    margin:0;
    padding:0;
    padding-left:5px;
    height: 24px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    
    &:focus{
      border-color: #999;
    }
  } 
}

.form-checkbox{
  
  label{
    display:block;
    padding:5px 0 ;
    user-select: none;
    line-height:23px;
    height:30px;
    text-align: center;
    
    span{
      position: relative;
      display: inline-block;
      top: 7px;
      width:21px;
      height:21px;
      margin-right: 10px;
      background: #fff;
      border: 2px solid #ddd;
      cursor: pointer;
      
      &:hover{
        border-color:#ee533a;
      }
    }
  }
  input{
    display:none;
    &:checked+label span{
      background: #fff url(../img/sign.jpg) no-repeat;
    }
  }
}
