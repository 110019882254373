.content{
  min-height: 200px;

  &-title{
    font-weight:normal;
  }
  &-img-wrapper{
    position: relative;
    padding-left:125px;
    min-height:100px;
  }
  &-img{
    position:absolute;
    left:60px;
    top:0;
  }
}

.sliders{
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}
.slider{
  position:relative;   
  border: 5px solid transparent;
  
  img{
    position:absolute; 
    display:block;
    left:0; 
    top:0;
    width:100%;
    height:100%;
  }
  
  &-long{
    width: 475px;
    height: 171px;
    border-left: none;
  }

  &-big{
    width: 716px;
    height: 301px;
    border-right: none;
  }

  &-small{
    width: 236px;
    height: 120px;
    border-left: none;
  }
  
  &-small2{
    width: 230px;
    height: 120px;
  }
}



.advantages{
  background: #e7e7e8;
  display: flex;
  flex-wrap: wrap;
  padding:15px 20px 0;
  margin: 20px 0;
  color:#555;
  text-shadow: 0 0 1px #fff;
  &-item{
    flex: 1 0 33%;
    box-sizing: border-box;
    padding: 13px 0 20px 105px; 
    margin-top: 5px;
    background-repeat: no-repeat;
    background-position: 40px 0;
    h3 a{
      color:#777;
      font-weight: bold;
      text-decoration: none;

      &:hover{
        color:#333;
      }
    }
  }
  &-item:nth-child(1){
    background-image: url(../img/milijo.png);
  }
  &-item:nth-child(2){
    background-image: url(../img/jober.png);
  }
  &-item:nth-child(3){
    background-image: url(../img/retni.png);
  }
  &-item:nth-child(4){
    background-image: url(../img/investorer.png);
  }
  &-item:nth-child(5){
    background-image: url(../img/nestek.png);
  }
  &-item:nth-child(6){
    background-image: url(../img/nyheter.png);
  }
  
  &-title{
    margin:0 0 15px;
    color:#777;
  }

  &-text{
    font-size: $font-size-small;
  }
}

